<script lang="ts" setup>
import {
  onActivated,
  onDeactivated,
  onMounted,
  onUnmounted,
  toRef,
} from 'vue';

import { VCover } from 'web/src/components/Cover';
import { useKeepAlive } from 'web/src/modules/core/composables/keep-alive';
import { useSeoMetaParameters } from 'web/src/modules/seo/composables';
import FilterTransition from 'web/src/modules/sportline/components/FilterTransition/FilterTransition.vue';
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { extendedEventBlockLive } from 'web/src/modules/sportline/submodules/extended-event';
import LiveBetSwipeListFilter from 'web/src/modules/sportline/submodules/live/components/Filter/bet-swipe/SportlineLiveFilter.vue';
import LiveDefaultListFilter from 'web/src/modules/sportline/submodules/live/components/Filter/default/SportlineLiveFilter.vue';
import SportlineLiveNoEvents from 'web/src/modules/sportline/submodules/live/components/SportlineLiveNoEvents.vue';
import useLiveSegmentPage from 'web/src/modules/sportline/submodules/live/composables/useLiveSegmentPage';
import LiveFavoritesTab from 'web/src/modules/sportline/submodules/live/views/LiveFavoritesTab.vue';
import { SegmentPageTabType } from 'web/src/modules/sportline/submodules/segment/enums';

import LiveBetSwipeTab from './sport-tab/LiveBetSwipeTab.vue';
import LiveDefaultTab from './sport-tab/LiveDefaultTab.vue';
import VirtualGroupsList from './VirtualGroupsList.vue';

const {
  isReady,
  totalCount,
  allGroupForFilter,
  mainGroups,
  groupsForDisplay,
  favoriteGroupForFilter,
  streamEventsGroupForFilter,
  isFilterTransitionFadeEffectEnabled,
  filterTransitionDuration,
  seoMetaInfo,
  selectedFilter,
  activeTransitionId,
  setActiveTransitionId,
  canShowLiveForGroup,
  canShowPrematchForGroup,
  listTransition,
  onChangeFilter,
  onActivatedFiltersHook,
  onDeactivatedFiltersHook,
  getEmptyEventsComponentProperties,
} = useLiveSegmentPage();

useSeoMetaParameters(seoMetaInfo);

const { isBetSwipeLiveLayoutEnabled } = useSportlineLayoutSettings();
const sportlineSettingsStore = useSportlineSettingsStore();
const isVirtualListEnabled = toRef(sportlineSettingsStore, 'isVirtualListEnabled');

onMounted(onActivatedFiltersHook);
onActivated(onActivatedFiltersHook);
onUnmounted(onDeactivatedFiltersHook);
onDeactivated(onDeactivatedFiltersHook);

useKeepAlive();
</script>

<template>
  <div v-auto-id="'LiveRoutePage'" class="component-wrapper">
    <FilterTransition
      v-if="isReady"
      ref="listTransition"
      key="sport-events-segment-page"
      :disabled="!isFilterTransitionFadeEffectEnabled"
      :fade-in-duration="filterTransitionDuration.fadeIn"
      :fade-out-duration="filterTransitionDuration.fadeOut"
      @change-id="setActiveTransitionId"
    >
      <template #filter="{ duration, afterEnter, afterLeave, beforeEnter, beforeLeave }">
        <LiveBetSwipeListFilter
          v-if="null && isBetSwipeLiveLayoutEnabled"
          :class="[
            [$style['events-list-filter']],
            [$style['events-list-filter--extra-paddings']],
          ]"
          :total-count="totalCount"
          :all-group="allGroupForFilter"
          :favorite-group="favoriteGroupForFilter"
          :stream-group="streamEventsGroupForFilter"
          :groups="mainGroups"
          @change-filter="onChangeFilter"
        >
          <template #afterItem="{ item }">
            <transition
              name="fade"
              :duration="duration"
              @before-leave="beforeLeave(item.transitionId)"
              @after-leave="afterLeave"
              @before-enter="beforeEnter(item.transitionId)"
              @after-enter="afterEnter"
            >
              <div
                v-if="item.isActive"
                :key="item.key"
              />
            </transition>
          </template>
        </LiveBetSwipeListFilter>
        <LiveDefaultListFilter
          v-else
          :class="$style['events-list-filter']"
          :total-count="totalCount"
          :all-group="allGroupForFilter"
          :favorite-group="favoriteGroupForFilter"
          :stream-group="streamEventsGroupForFilter"
          :groups="mainGroups"
          @change-filter="onChangeFilter"
        >
          <template #afterItem="{ item }">
            <transition
              name="fade"
              :duration="duration"
              @before-leave="beforeLeave(item.transitionId)"
              @after-leave="afterLeave"
              @before-enter="beforeEnter(item.transitionId)"
              @after-enter="afterEnter"
            >
              <div
                v-if="item.isActive"
                :key="item.key"
              />
            </transition>
          </template>
        </LiveDefaultListFilter>
      </template>

      <template #content="{ isFadeOut, extraStyle }">
        <div
          v-show="!isFadeOut"
          key="sport-events-segment-content"
          :class="{
            [$style['events-list']]: true,
            [$style['events-list--extra-paddings']]: null && isBetSwipeLiveLayoutEnabled,
          }"
          :style="extraStyle"
        >
          <SportlineLiveNoEvents
            v-if="totalCount === 0 && allGroupForFilter && canShowLiveForGroup(allGroupForFilter)"
            v-bind="getEmptyEventsComponentProperties(allGroupForFilter)"
            :key="`${allGroupForFilter.key}-empty`"
            :title="$t('WEB2_LIVE_NOEVENTS_TITLE')"
          />

          <VirtualGroupsList
            v-if="isVirtualListEnabled"
            :groups="groupsForDisplay"
            :extended-block-type="extendedEventBlockLive"
            :selected-filter="selectedFilter"
            :active-transition-id="activeTransitionId"
            :can-collapse-sport="!!allGroupForFilter && canShowLiveForGroup(allGroupForFilter)"
          />
          <template v-else>
            <VCover
              v-for="group in groupsForDisplay"
              :key="group.key"
              :opened="canShowLiveForGroup(group)"
            >
              <template #default="{ isLoaded, isOpen }">
                <LiveFavoritesTab
                  v-if="isLoaded && group.type === SegmentPageTabType.Favorite && isOpen"
                  :key="`${group.key}-loaded`"
                  v-data-test="{ el: 'favorites-tab', shown: isOpen || canShowPrematchForGroup(group) }"
                  :is-active="isOpen"
                />
                <template v-else-if="isLoaded && group.type === SegmentPageTabType.Default">
                  <LiveBetSwipeTab
                    v-if="null && isBetSwipeLiveLayoutEnabled"
                    :key="`${group.key}-bs-loaded`"
                    v-data-test="{ el: 'group-tab', shown: isOpen || canShowPrematchForGroup(group) }"
                    :group="group"
                    :is-open="isOpen"
                    :can-show-prematch="canShowPrematchForGroup(group)"
                  />
                  <LiveDefaultTab
                    v-else
                    :key="`${group.key}-loaded`"
                    v-data-test="{ el: 'group-tab', shown: isOpen || canShowPrematchForGroup(group) }"
                    :group="group"
                    :can-expand-elements="!!allGroupForFilter && canShowLiveForGroup(allGroupForFilter)"
                    :is-open="isOpen"
                    :can-show-prematch="canShowPrematchForGroup(group)"
                  />
                </template>
                <div
                  v-else
                  :key="`${group.key}-unloaded`"
                />
              </template>
            </VCover>
          </template>
        </div>
      </template>
    </FilterTransition>
  </div>
</template>

<style module lang="scss">
@import 'web/src/modules/sportline/styles/sportline-page';

@include for-layout using ($isDesktop) {
  .events-list {
    position: relative;
    padding: if($isDesktop, 0, 0 8px);

    &--extra-paddings {
      @include sportline-page-paddings($isDesktop);
    }
  }

  .events-list-filter {
    padding: if($isDesktop, 0 0 8px 0, 8px 0);

    &--extra-paddings {
      @include sportline-page-paddings($isDesktop);

      margin: 0 0 16px;
    }
  }
}
</style>
