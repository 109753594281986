import type { Ref } from 'vue';

import type { ExtendedEventsId, ExtendedEventsIds } from 'web/src/modules/sportline/submodules/extended-event/store/types';
import type {
  GetElementSize,
  VirtualListEventElement,
} from 'web/src/modules/sportline/views/virtual-list-renderer/types';
import { VirtualListRendererElementSize } from 'web/src/modules/sportline/views/virtual-list-renderer/constants';

import { getEventVirtualElementId } from './getElementId';

export type ExpandedEventsHeightMap = Record<ExtendedEventsId, Optional<number>>;

interface CreateGetEventHeightProps {
  expandedHeights: Ref<ExpandedEventsHeightMap>;
  expandedIds: Ref<ExtendedEventsIds>;
}

export function createGetEventElementSize(
  { expandedIds, expandedHeights }: CreateGetEventHeightProps,
): GetElementSize<VirtualListEventElement> {
  return (element) => {
    const {
      sportlineEvent,
      isLastEventInLeague,
      isLastLeagueInRegion,
      isLastRegionInSport,
      isLastSport,
    } = element;
    const { id } = sportlineEvent.sportEvent;

    const customHeight = expandedHeights.value[id];
    const isExtended = expandedIds.value.includes(id);

    const sportsGap = !isLastSport ? VirtualListRendererElementSize.SportsGap : undefined;
    const regionsGap = !isLastRegionInSport ? VirtualListRendererElementSize.RegionsGap : undefined;
    const leaguesGap = !isLastLeagueInRegion ? VirtualListRendererElementSize.LeaguesGap : undefined;
    const eventsGap = !isLastEventInLeague ? VirtualListRendererElementSize.EventsGap : undefined;
    const gap = eventsGap ?? leaguesGap ?? regionsGap ?? sportsGap ?? 0;

    return {
      id: getEventVirtualElementId(sportlineEvent),
      height: customHeight ?? (VirtualListRendererElementSize.EventHeight + gap),
      keepInDOM: isExtended,
    };
  };
}
