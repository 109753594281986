import type { Ref } from 'vue';
import { computed } from 'vue';

import type {
  RecycleScrollerItemDimension,
  RecycleScrollerListItem,
  RecycleScrollerListItemOffset,
  RecycleScrollerResourcesListState,
} from 'web/src/components/RecycleScroller/types';
import { getItemSizeStyle } from 'web/src/components/RecycleScroller/utils/getItemSizeStyle';

export type RecycleScrollerVisibleList = Ref<RecycleScrollerListItem[]>;

export function createVisibleList(
  visibleItemsState: RecycleScrollerResourcesListState,
  dimensions: Ref<RecycleScrollerItemDimension[]>,
): RecycleScrollerVisibleList {
  return computed<RecycleScrollerListItem[]>(() => {
    const visibleList: RecycleScrollerListItem[] = [];
    let itemOffsetX = 0;
    let itemOffsetY = 0;

    for (const [index, itemSize] of dimensions.value.entries()) {
      const itemOffset: RecycleScrollerListItemOffset = {
        width: itemOffsetX,
        height: itemOffsetY,
      };

      itemOffsetX += itemSize.width ?? 0;
      itemOffsetY += itemSize.height ?? 0;

      const isOverScreen = index < visibleItemsState.value.start;
      if (isOverScreen && !itemSize.keepInDOM) { continue; }
      if (index >= visibleItemsState.value.end) { break; }

      const item: RecycleScrollerListItem = {
        size: itemSize,
        offset: itemOffset,
        style: getItemSizeStyle(itemSize, isOverScreen),
        index,
      };

      visibleList.push(item);
    }

    return visibleList;
  });
}
