import type { Ref } from 'vue';
import { computed } from 'vue';

import type {
  RecycleScrollerItemByDirection,
  RecycleScrollerItemDimension,
  RecycleScrollerItemOptions,
  SizeByDirection,
} from '../types';
import { RecycleScrollerDirection } from '../enums';
import { convertEdgeToItemSize } from './getItemSizeValue';

export function getItemOptionDimensions(options: RecycleScrollerItemOptions[]): RecycleScrollerItemDimension[] {
  return options.map(({
    id,
    width,
    height,
    keepInDOM,
  }) => ({
    id,
    width: width ? convertEdgeToItemSize(width) : undefined,
    height: height ? convertEdgeToItemSize(height) : undefined,
    keepInDOM,
  }));
}

export function getDimensionSizeForDirection(
  dimension: RecycleScrollerItemDimension,
  direction: RecycleScrollerDirection = RecycleScrollerDirection.VERTICAL,
): SizeByDirection {
  return direction === RecycleScrollerDirection.VERTICAL
    ? (dimension.height ?? 0)
    : (dimension.width ?? 0);
}

export function createListItemsByDirection(
  dimensions: Ref<RecycleScrollerItemDimension[]>,
  direction: RecycleScrollerDirection = RecycleScrollerDirection.VERTICAL,
): Ref<RecycleScrollerItemByDirection[]> {
  return computed<RecycleScrollerItemByDirection[]>(() => dimensions.value.map((dimension) => ({
    id: dimension.id,
    size: getDimensionSizeForDirection(dimension, direction),
    keepInDOM: dimension.keepInDOM,
  })));
}

export function createDimensionSizesRefForDirection(
  dimensions: Ref<RecycleScrollerItemDimension[]>,
  direction: RecycleScrollerDirection = RecycleScrollerDirection.VERTICAL,
): Ref<SizeByDirection[]> {
  return computed(() => dimensions.value.map((dimension) => getDimensionSizeForDirection(dimension, direction)));
}
