import type { Ref } from 'vue';
import { computed } from 'vue';

import type { RecycleScrollerItemOptions, RecycleScrollerResourcesListState } from '../types';
import type { RecycleScrollerGetDistance } from '../utils';
import { RecycleScrollerDirection } from '../enums';
import { createComputedTotalSize } from '../utils';

export type WrapperPropsStyle = {
  width: string;
  height: string;
  marginTop: string;
} | {
  width: string;
  height: string;
  marginLeft: string;
  display: string;
};

export interface WrapperProps {
  style: WrapperPropsStyle;
}

interface UseWrapperPropsProps {
  options: Ref<RecycleScrollerItemOptions[]>;
  visibleItemsState: RecycleScrollerResourcesListState;
}

interface UseWrapperPropsComposable {
  wrapperProps: Ref<WrapperProps>;
}

export function useHorizontalWrapperProps(
  props: UseWrapperPropsProps,
  getDistanceLeft: RecycleScrollerGetDistance,
): UseWrapperPropsComposable {
  const { options, visibleItemsState } = props;

  const totalWidth = createComputedTotalSize(options, RecycleScrollerDirection.HORIZONTAL);
  const offsetLeft = computed<number>(() => getDistanceLeft(visibleItemsState.value.start));
  const wrapperProps = computed<WrapperProps>(() => ({
    style: {
      height: '100%',
      width: `${totalWidth.value - offsetLeft.value}px`,
      marginLeft: `${offsetLeft.value}px`,
      display: 'flex',
    },
  }));

  return { wrapperProps };
}

export function useVerticalWrapperProps(
  props: UseWrapperPropsProps,
  getDistanceTop: RecycleScrollerGetDistance,
): UseWrapperPropsComposable {
  const { options, visibleItemsState } = props;

  const totalHeight = createComputedTotalSize(options, RecycleScrollerDirection.VERTICAL);
  const offsetTop = computed<number>(() => getDistanceTop(visibleItemsState.value.start));
  const wrapperProps = computed<WrapperProps>(() => ({
    style: {
      width: '100%',
      height: `${totalHeight.value - offsetTop.value}px`,
      marginTop: `${offsetTop.value}px`,
    },
  }));

  return { wrapperProps };
}
