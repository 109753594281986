<script setup lang="ts">
import { toRef } from 'vue';

import type {
  SportlineFavoriteRegionsFilterEmits,
  SportlineFavoriteRegionsFilterProps,
} from 'web/src/modules/sportline/submodules/favorites/components/FavoriteRegionsFilter/types';
import {
  useFavoriteRegionFilterTabs,
} from 'web/src/modules/sportline/submodules/favorites/components/FavoriteRegionsFilter/composables';
import SportlineFavoriteRegionsFilter from 'web/src/modules/sportline/submodules/favorites/components/FavoriteRegionsFilter/SportlineFavoriteRegionsFilter.vue';
import useSportlineLiveStore from 'web/src/modules/sportline/submodules/live/store/useSportlineLiveStore';

const props = defineProps<SportlineFavoriteRegionsFilterProps>();
const emit = defineEmits<SportlineFavoriteRegionsFilterEmits>();

const selectedRegionFamily = toRef(props, 'selectedRegionFamily', null);
const favoriteEventsList = toRef(useSportlineLiveStore(), 'favoriteEventsFullList');

function selectRegionFamily(value: Maybe<string>): void {
  emit('select-region-family', value);
}

const {
  activeTabIndex,
  favoriteRegionsTabs,
} = useFavoriteRegionFilterTabs({ selectedRegionFamily }, { selectRegionFamily }, favoriteEventsList);
</script>

<template>
  <SportlineFavoriteRegionsFilter v-auto-id="'LiveFavoriteRegionsFilter'"
    :active-tab-index="activeTabIndex"
    :favorite-regions-tabs="favoriteRegionsTabs"
    :selected-region-family="selectedRegionFamily"
  />
</template>
