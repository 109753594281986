<script lang="ts" setup>
import { toRef } from 'vue';

import { createSportlineFragmentBasisKey } from 'web/src/modules/sportline/composables/list/utils';
import { useSportFromCache } from 'web/src/modules/sportline/submodules/cache';
import SportHeadline from 'web/src/modules/sportline/views/headline/SportHeadline.vue';

import type { SportlineLiveNoEventsProps } from './types';

interface SportlineLiveNoEventsSlots {
  title(props: Dictionary<never>): void;
}

const props = defineProps<SportlineLiveNoEventsProps>();
defineSlots<SportlineLiveNoEventsSlots>();

const sportId = toRef(props, 'sportId', null);
const basisKey = createSportlineFragmentBasisKey({ sportId });
const sport = useSportFromCache(sportId);
</script>

<template>
  <div v-auto-id="'SportlineLiveNoEvents'">
    <slot name="title">
      <SportHeadline
        v-if="sport"
        :sport="sport"
        hide-events-counter
        :basis-key="basisKey"
      />
    </slot>

    <div :class="$style['no-events-content']">
      <div :class="$style['no-events-content__label']">
        {{ $t('WEB2_LIVE_NOEVENTS_TITLE') }}
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
@import 'web/src/modules/sportline/styles/no-events';
</style>
