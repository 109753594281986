import type { RouteLocationRaw } from 'vue-router';
import { computed, ref, toRefs } from 'vue';

import type { SportlineLiveNoEventsProps } from 'web/src/modules/sportline/submodules/live/components/types';
import type { GenericSegmentPageComposable } from 'web/src/modules/sportline/submodules/segment/composables/types';
import type { SportsSegmentPageListGroup } from 'web/src/modules/sportline/submodules/segment/types';
import type { SportElement } from 'web/src/modules/sportline/types';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import useSportlineLiveStore from 'web/src/modules/sportline/submodules/live/store/useSportlineLiveStore';
import { resolveLivePageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
import {
  useSegmentPageFilters,
  useSegmentPageResetFiltersHooks,
  useSegmentPageSeoMeta,
} from 'web/src/modules/sportline/submodules/segment/composables';
import { SegmentPageTabType } from 'web/src/modules/sportline/submodules/segment/enums';
import { isLiveCountersEmpty } from 'web/src/modules/sportline/utils';
import { SportEventsResponseChangeUtils } from 'web/src/modules/sportline/utils/rest';

interface LiveSegmentPageComposable extends
  GenericSegmentPageComposable<SportsSegmentPageListGroup> {
  getEmptyEventsComponentProperties(group?: SportsSegmentPageListGroup): SportlineLiveNoEventsProps;
}

export default function useLiveSegmentPage(): LiveSegmentPageComposable {
  const liveStore = useSportlineLiveStore();
  const {
    isReady,
    totalCount,
    selectedFilter,
    isFilterTransitionFadeEffectEnabled,
    sportElementSelectedInFilter,
    isFavoritesTabAvailable,
    filterTransitionDuration,
    liveEventsSportsMap,
    comingEventsSportsMap,
    sportElementsForFilter,
    compiledComingSports,
    customerOptimisticFavoriteSportEventsIds,
    customerOptimisticFavoriteLeaguesIds,
    rawLiveEventsResponse,
    customerStreamsSportEventsList,
  } = toRefs(liveStore);
  const {
    setBackgroundUpdateEnabled,
  } = liveStore;

  const mainGroups = computed<SportsSegmentPageListGroup[]>(() => {
    const selectedKey = selectedFilter.value;
    const liveElementsMap = liveEventsSportsMap.value;
    const comingElementsMap = comingEventsSportsMap.value;
    const expandFirstCount = 5;
    let isOpenByDefaultCount = 0;
    return (sportElementsForFilter.value || [])
      .map((sportElement: SportElement): SportsSegmentPageListGroup => {
        const transitionId = sportElement.sport.navigationParameters.urlName;
        const key = `${sportElement.sport.id}_${transitionId}`;
        const liveElement = liveElementsMap.get(transitionId);
        const prematchElement = comingElementsMap.get(transitionId);
        const isEmpty = isLiveCountersEmpty(liveElement);
        const isOpenByDefault = !isEmpty && isOpenByDefaultCount < expandFirstCount;

        if (isOpenByDefault) {
          isOpenByDefaultCount += 1;
        }

        return {
          filterElement: sportElement,
          liveElement,
          prematchElement,
          transitionId,
          allowedForTransitionIds: [CustomFilter.AllEvents, transitionId],
          isActive: selectedKey === transitionId,
          isEmpty,
          isEmptyComing: !prematchElement?.counters.prematch,
          key,
          type: SegmentPageTabType.Default,
          expandKey: `live-${sportElement.sport.representation.family}`,
          isOpenByDefault,
        };
      });
  });
  const compiledComingGroups = computed<SportsSegmentPageListGroup[]>(() => {
    const sportElements = compiledComingSports.value ?? [];

    if (sportElements.length === 0) {
      return [];
    }

    const selectedKey = selectedFilter.value as CustomFilter;
    return sportElements.map((sportElement: SportElement, index): SportsSegmentPageListGroup => {
      const transitionId = sportElement.sport.navigationParameters.urlName;
      const key = `coming-${sportElement.sport.id}_${transitionId}`;
      return {
        filterElement: sportElement,
        prematchElement: sportElement,
        transitionId: CustomFilter.AllEvents,
        allowedForTransitionIds: [CustomFilter.AllEvents],
        isActive: selectedKey === CustomFilter.AllEvents,
        isEmpty: false, // false to hide "No live" block
        isEmptyComing: !sportElement.counters.prematch,
        key,
        type: SegmentPageTabType.Default,
        showComingName: true,
        doShowTitleComponent: index === 0,
      };
    });
  });
  const favoriteGroups = computed<SportsSegmentPageListGroup[]>(() => {
    const selectedKey = selectedFilter.value as CustomFilter;
    const eventsIds = customerOptimisticFavoriteSportEventsIds.value;
    const leaguesIds = customerOptimisticFavoriteLeaguesIds.value;
    const response = rawLiveEventsResponse.value;
    const hasFavorites = SportEventsResponseChangeUtils.isSomeIdsInList(response, { eventsIds, leaguesIds });

    return hasFavorites
      ? [{
          transitionId: CustomFilter.Favorites,
          allowedForTransitionIds: [CustomFilter.Favorites],
          isActive: selectedKey === CustomFilter.Favorites,
          isEmpty: false,
          isEmptyComing: true,
          key: 'favorite',
          type: SegmentPageTabType.Favorite,
          showComingName: true,
        }]
      : [];
  });
  const streamEventsGroups = computed(() => {
    const selectedKey = selectedFilter.value as CustomFilter;
    return (customerStreamsSportEventsList.value || [])
      .map((sportElement: SportElement): SportsSegmentPageListGroup => ({
        filterElement: sportElement,
        liveElement: sportElement,
        prematchElement: sportElement,
        transitionId: CustomFilter.Stream,
        allowedForTransitionIds: [CustomFilter.Stream],
        isActive: selectedKey === CustomFilter.Stream,
        isEmpty: false, // false to hide "No live" block
        isEmptyComing: true,
        key: `stream${sportElement.sport.id}`,
        type: SegmentPageTabType.Default,
        showComingName: true,
      }));
  });
  const groupsForDisplay = computed(() => [
    ...mainGroups.value,
    ...favoriteGroups.value,
    ...streamEventsGroups.value,
    ...compiledComingGroups.value,
  ]);
  // @TODO hasStreamEvents from store
  const hasStreamEvents = computed(() => streamEventsGroups.value.length > 0);

  const {
    allGroupForFilter,
    favoriteGroupForFilter,
    streamEventsGroupForFilter,
    activeTransitionId,
    setActiveTransitionId,
    canShowLiveForGroup,
    canShowPrematchForGroup,
  } = useSegmentPageFilters<SportsSegmentPageListGroup>({
    isFilterTransitionFadeEffectEnabled,
    filterTransitionDuration,
    totalCount,
    selectedFilter,
    mainGroups,
    hasStreamEvents,
    isFavoritesTabAvailable,
  });

  function getEmptyEventsComponentProperties(group?: SportsSegmentPageListGroup): SportlineLiveNoEventsProps {
    return group?.filterElement ? { sportId: group.filterElement.sport.id } : {};
  }

  const {
    listTransition,
    onChangeFilter,
    onActivatedFiltersHook,
    onDeactivatedFiltersHook,
  } = useSegmentPageResetFiltersHooks({
    isReady,
    selectedFilter,
    groupsForDisplay,
    setBackgroundUpdateEnabled,
    persistentLocationKey: 'live',
    getDefaultPageLocation(): RouteLocationRaw {
      return resolveLivePageLink({});
    },
  });
  const {
    seoMetaInfo,
  } = useSegmentPageSeoMeta({
    isReady,
    activeSportElement: sportElementSelectedInFilter,
    activeRegionElement: ref(null),
    groupsForDisplay,
    canShowLiveForGroup,
    canShowPrematchForGroup,
  });

  return {
    isReady,
    totalCount,
    allGroupForFilter,
    mainGroups,
    groupsForDisplay,
    favoriteGroupForFilter,
    streamEventsGroupForFilter,
    isFilterTransitionFadeEffectEnabled,
    filterTransitionDuration,
    seoMetaInfo,
    selectedFilter,
    activeTransitionId,
    setActiveTransitionId,
    canShowLiveForGroup,
    canShowPrematchForGroup,
    listTransition,
    onChangeFilter,
    onActivatedFiltersHook,
    onDeactivatedFiltersHook,
    getEmptyEventsComponentProperties,
  };
}
