import type { MaybeRef } from 'vue';
import { toValue } from 'vue';

import type { ExpandedSportlineBlockMap } from 'web/src/modules/sportline/composables/expandable-block/types';
import type { SportElement } from 'web/src/modules/sportline/types';
import type { SportlineFragmentBasisKey } from 'web/src/modules/sportline/types/list';
import { getIsExpandedFromMap } from 'web/src/modules/sportline/composables/expandable-block/utils';
import { getSportElementEventsCounters } from 'web/src/modules/sportline/utils';

import type {
  VirtualListElement,
  VirtualListEventElement,
  VirtualListLeagueHeadlineElement,
  VirtualListSportHeadlineElement,
} from '../types';
import { VirtualListBlockLayout, VirtualListElementType } from '../enums';

interface SportElementProps {
  isLastSport?: boolean;
  blockLayout?: VirtualListBlockLayout;
  collapseSportKey?: MaybeRef<Maybe<string>>;
  isDefaultCollapsedSport?: MaybeRef<boolean>;
  expandedSportStateMap?: MaybeRef<ExpandedSportlineBlockMap>;
  basisKey: SportlineFragmentBasisKey;
}

export function getGroupsForSportElement(
  sportElement: Maybe<SportElement>,
  props: SportElementProps,
): VirtualListElement[] {
  if (!sportElement) { return []; }

  const isLastSport = props.isLastSport ?? false;
  const blockLayout = props.blockLayout ?? VirtualListBlockLayout.Default;
  const isDefaultCollapsedSport = toValue(props.isDefaultCollapsedSport ?? false);
  const collapseSportKey = toValue(props.collapseSportKey ?? null);
  const expandedSportStateMap = toValue(props.expandedSportStateMap ?? {});
  const { basisKey } = props;

  const isExpandedSport = getIsExpandedFromMap(expandedSportStateMap, collapseSportKey, !isDefaultCollapsedSport);

  const sportHeadline: VirtualListSportHeadlineElement = {
    type: VirtualListElementType.SportHeadline,
    sport: sportElement.sport,
    isLastSport,
    blockLayout,
    eventsCount: getSportElementEventsCounters(sportElement).total,
    basisKey,
    collapseSportKey,
  };

  if (!isExpandedSport) {
    return [sportHeadline];
  }

  const regionsCount = sportElement.regions.length;
  const elements = (sportElement.regions).flatMap((regionElement, regionIndex) => {
    const leaguesCount = regionElement.leagues.length;
    const isLastRegionInSport = regionsCount === regionIndex + 1;

    return regionElement.leagues.flatMap((leagueElement, leagueIndex) => {
      const isLastLeagueInRegion = leaguesCount === leagueIndex + 1;
      const leagueHeadline: VirtualListLeagueHeadlineElement = {
        type: VirtualListElementType.LeagueHeadline,
        sport: sportElement.sport,
        region: regionElement.region,
        leagueElement,
        isLastLeagueInRegion,
        isLastRegionInSport,
        isLastSport,
        basisKey,
      };

      const eventsCount = leagueElement.sportEvents.length;
      return [
        leagueHeadline,
        ...leagueElement.sportEvents.flatMap((sportlineEvent, eventIndex): VirtualListEventElement => ({
          type: VirtualListElementType.Event,
          sport: sportElement.sport,
          sportlineEvent,
          isLastEventInLeague: eventsCount === eventIndex + 1,
          isLastLeagueInRegion,
          isLastRegionInSport,
          isLastSport,
          blockLayout,
          basisKey,
        })),
      ];
    });
  });

  return [
    sportHeadline,
    ...elements,
  ];
}
