import type { Ref } from 'vue';
import { computed } from 'vue';

import type {
  RecycleScrollerOverscan,
  RecycleScrollerResourcesListState,
  VisibleItemsEndIndex,
  VisibleItemsOffsetIndex,
  VisibleItemsStartIndex,
} from '../../types';
import type { VisibleItemsViewCapacity } from './createGetViewCapacity';

export function createVisibleItemsStartIndex(
  offset: VisibleItemsOffsetIndex,
  overscan: Ref<RecycleScrollerOverscan>,
): VisibleItemsStartIndex {
  return computed(() => Math.max(
    offset.value - overscan.value,
    0,
  ));
}

export function createVisibleItemsEndIndex(
  offset: VisibleItemsOffsetIndex,
  viewCapacity: VisibleItemsViewCapacity,
  overscan: Ref<RecycleScrollerOverscan>,
  sourceLength: Ref<number>,
): VisibleItemsEndIndex {
  return computed(() => Math.min(
    offset.value + viewCapacity.value + overscan.value,
    sourceLength.value,
  ));
}

export function createVisibleItemsState(
  start: VisibleItemsStartIndex,
  end: VisibleItemsEndIndex,
): RecycleScrollerResourcesListState {
  return computed(() => ({ start: start.value, end: end.value }));
}
