import type { Ref } from 'vue';

import type { RecycleScrollerDirection } from '../enums';
import type {
  RecycleScrollerHiddenSize,
  RecycleScrollerResourcesContainer,
  SetScrollableElementDimension,
} from '../types';
import { elementPositionKeys, offsetKeys, viewCapacityKeys } from '../constants';
import { getElementPosition } from './getElementPosition';

export type RecycleScrollerCalculateRange = () => void;

interface CreateCalculateRangeProps {
  direction: RecycleScrollerDirection;
  isPageMode: Ref<boolean>;
  hiddenSize: RecycleScrollerHiddenSize;
  containerRef: RecycleScrollerResourcesContainer;
  listenerTargetRef: RecycleScrollerResourcesContainer;
  setScrollableElementDimension: SetScrollableElementDimension;
}

export function createCalculateRange(props: CreateCalculateRangeProps): RecycleScrollerCalculateRange {
  const {
    isPageMode,
    direction,
    hiddenSize,
    containerRef,
    listenerTargetRef,
    setScrollableElementDimension,
  } = props;

  const offsetKey = offsetKeys[direction];
  const viewCapacityKey = viewCapacityKeys[direction];
  const elementPositionKey = elementPositionKeys[direction];

  return (): void => {
    const scrollableElement = listenerTargetRef.value;
    const listElement = containerRef.value;

    if (!scrollableElement || !listElement) { return; }

    const elementPosition = getElementPosition(listElement, scrollableElement)[elementPositionKey];
    const gapBefore = isPageMode.value ? elementPosition - hiddenSize.value.start : 0;

    setScrollableElementDimension({
      scrollOffset: scrollableElement[offsetKey] - gapBefore,
      containerSize: scrollableElement[viewCapacityKey],
    });
  };
}
