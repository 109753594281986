import type { CSSProperties } from 'vue';

import type { RecycleScrollerItemDimension } from '../types';

export function getItemSizeStyle(
  { width, height }: RecycleScrollerItemDimension,
  isDetached?: boolean,
): CSSProperties {
  const detachedStyles: CSSProperties = isDetached
    ? {
        position: 'absolute',
        left: height ? '0' : `-${(width || 0)}px`,
        right: height ? '0' : undefined,
        top: width ? '0' : `-${height || 0}px`,
        bottom: width ? '0' : undefined,
      }
    : {};

  return {
    width: width ? `${width}px` : undefined,
    height: height ? `${height}px` : undefined,
    ...detachedStyles,
  };
}
