import type { RecycleScrollerResourcesContainer } from '../types';
import type { RecycleScrollerGetDistance } from './createGetDistance';
import { RecycleScrollerDirection } from '../enums';

const scrollToDictionaryForElementScrollKey = {
  [RecycleScrollerDirection.HORIZONTAL]: 'scrollLeft',
  [RecycleScrollerDirection.VERTICAL]: 'scrollTop',
} as const;

export type RecycleScrollerScrollTo = (index: number) => void;

export type RecycleScrollerScrollBy = (size: number, doCalculateRange?: boolean) => void;

export function createScrollTo(
  type: RecycleScrollerDirection,
  calculateRange: () => void,
  getDistance: RecycleScrollerGetDistance,
  containerRef: RecycleScrollerResourcesContainer,
): RecycleScrollerScrollTo {
  return (index: number): void => {
    if (!containerRef.value) { return; }

    const scrollAttrName = scrollToDictionaryForElementScrollKey[type];

    containerRef.value[scrollAttrName] = getDistance(index);

    calculateRange();
  };
}

export function createScrollBy(
  type: RecycleScrollerDirection,
  calculateRange: () => void,
  containerRef: RecycleScrollerResourcesContainer,
): RecycleScrollerScrollBy {
  return (size: number, doCalculateRange = true): void => {
    if (!containerRef.value) { return; }

    const scrollAttrName = scrollToDictionaryForElementScrollKey[type];

    containerRef.value[scrollAttrName] += size;

    if (doCalculateRange) { calculateRange(); }
  };
}
