import type { Ref } from 'vue';

import type {
  RecycleScrollerOverscan,
  RecycleScrollerResourcesListState,
  ScrollableElementContainerSize,
  ScrollableElementScrollOffset,
  SizeByDirection,
  VisibleItemsOffset,
} from '../types';
import {
  createVisibleItemsEndIndex,
  createVisibleItemsOffset,
  createVisibleItemsStartIndex,
  createVisibleItemsState,
  createVisibleItemsViewCapacity,
} from '../utils/visible-items';

export interface UseVisibleItemsState {
  visibleItemsOffset: VisibleItemsOffset;
  visibleItemsState: RecycleScrollerResourcesListState;
}

/**
 * Visible list start and end calculation
 */
export function useVisibleItemsState(
  scrollOffset: ScrollableElementScrollOffset,
  dimensionSizes: Ref<SizeByDirection[]>,
  overscan: Ref<RecycleScrollerOverscan>,
  containerSize: ScrollableElementContainerSize,
  sourceLength: Ref<number>,
): UseVisibleItemsState {
  const visibleItemsOffset = createVisibleItemsOffset(scrollOffset, dimensionSizes);
  const visibleItemsStartIndex = createVisibleItemsStartIndex(visibleItemsOffset.index, overscan);
  const visibleItemsViewCapacity = createVisibleItemsViewCapacity(scrollOffset, containerSize, visibleItemsStartIndex, dimensionSizes);
  const visibleItemsEndIndex = createVisibleItemsEndIndex(visibleItemsOffset.index, visibleItemsViewCapacity, overscan, sourceLength);

  return {
    visibleItemsOffset,
    visibleItemsState: createVisibleItemsState(visibleItemsStartIndex, visibleItemsEndIndex),
  };
}
