import type { MaybeRef, Ref } from 'vue';
import {
  computed,
  ref,
  shallowRef,
} from 'vue';

import type {
  RecycleScrollerItemDimension,
  RecycleScrollerItemOptions,
  RecycleScrollerResourcesContainer,
  RecycleScrollerResourcesSource,
  ScrollableElementContainerSize,
  ScrollableElementScrollOffset,
  SetScrollableElementDimension,
  SetScrollableElementDimensionPayload,
} from '../types';
import { getItemOptionDimensions } from '../utils';

export type UseRecycleScrollerResourcesList = MaybeRef<RecycleScrollerItemOptions[]>;

export interface UseRecycleScrollerResourcesProps {
  options: UseRecycleScrollerResourcesList;
}

export interface UseRecycleScrollerResourcesComposable {
  scrollOffset: ScrollableElementScrollOffset;
  containerSize: ScrollableElementContainerSize;
  source: RecycleScrollerResourcesSource;
  dimensions: Ref<RecycleScrollerItemDimension[]>;
  containerRef: RecycleScrollerResourcesContainer;
  listenerTargetRef: RecycleScrollerResourcesContainer;
  isPageMode: Ref<boolean>;
  sourceLength: Ref<number>;
  setScrollableElementDimension: SetScrollableElementDimension;
}

export function useRecycleScrollerResources(
  props: UseRecycleScrollerResourcesProps,
): UseRecycleScrollerResourcesComposable {
  const { options } = props;

  const containerRef = ref<HTMLElement | null>(null);
  const listenerTargetRef = ref<HTMLElement | null>(null);

  const source = shallowRef(options);

  const dimensions = computed(() => getItemOptionDimensions(source.value));
  const isPageMode = computed<boolean>(() => (containerRef.value !== listenerTargetRef.value));
  const sourceLength = computed<number>(() => source.value.length);

  /** Refs for element dimensions calculated on scroll */
  const scrollOffset = ref(0);
  const containerSize = ref(0);

  return {
    scrollOffset,
    containerSize,
    source,
    dimensions,
    containerRef,
    listenerTargetRef,
    isPageMode,
    sourceLength,
    setScrollableElementDimension(value: SetScrollableElementDimensionPayload): void {
      scrollOffset.value = value.scrollOffset;
      containerSize.value = value.containerSize;
    },
  };
}
