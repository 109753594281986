import type {
  RouteLocationNormalized,
} from 'vue-router';
import { toRef } from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';

import type { AbstractPrefetchResult, AppVueRouter } from '@core/router';
import { useRootNavigationStore } from '@core/navigation';
import { AbstractPrefetch } from '@core/router';

import type { SportFamily } from 'web/src/modules/sportline/enums';
import useSportlineLiveStore from 'web/src/modules/sportline/submodules/live/store/useSportlineLiveStore';
import { useSportlinePersistLocation } from 'web/src/modules/sportline/submodules/persist-filters';
import { isOptionalLiveFilterSportFamily } from 'web/src/modules/sportline/submodules/segment/guards';
import { getSportlinePageNavigationParameters } from 'web/src/modules/sportline/utils/navigation';

export default class LivePagePrefetch extends AbstractPrefetch {
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
  ): AbstractPrefetchResult {
    const liveStore = useSportlineLiveStore();

    const sportFamily = (to.params?.family as string | undefined)?.toLowerCase() as SportFamily;
    const isPageEnabled = toRef(liveStore, 'isPageEnabled');
    const allowedSportFamily = toRef(liveStore, 'allowedSportFamily');

    if (!isPageEnabled.value || !isOptionalLiveFilterSportFamily(sportFamily, allowedSportFamily.value)) {
      return { name: RouteName.ERROR_NOT_FOUND_404 };
    }

    const navigationStore = useRootNavigationStore();

    const { setPersistLocation } = useSportlinePersistLocation();
    const navigationParameters = getSportlinePageNavigationParameters(to);
    setPersistLocation('live', navigationParameters);

    if (!liveStore.isReady) {
      navigationStore.setOnlyFirstRouteContentLoading(RouteName.SPORTLINE_LIVE_EVENTS);
      void liveStore.initialRequests().then(() => {
        liveStore.setSelectedFilter(sportFamily);
        navigationStore.setRouteContentLoaded(RouteName.SPORTLINE_LIVE_EVENTS);
      });
    } else {
      liveStore.setSelectedFilter(sportFamily);
      liveStore.syncBackgroundRequests();
      navigationStore.setRouteContentLoaded(RouteName.SPORTLINE_LIVE_EVENTS);
    }
  }
}
